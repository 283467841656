import L from 'leaflet'

import 'leaflet/dist/leaflet.css'
import markerIconImg from 'leaflet/dist/images/marker-icon.png'
import markerShadowImg from 'leaflet/dist/images/marker-shadow.png'

class WidgetMapHandler extends elementorModules.frontend.handlers.Base {
    update() {
        const container = document.querySelector('.map__container')
        const coordinates = JSON.parse(container.dataset.coordinates)
        const jakarta = [-6.403, 106.831]
        const map = L.map('map', {
            minZoom: 5,
        }).setView(jakarta, 5)

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map)

        // Store markers in an array
        const markers = []

        const markerIcon = L.icon({
            iconUrl: markerIconImg,
            shadowUrl: markerShadowImg,
        })

        // Add markers to the map
        coordinates.forEach((el) => {
            const swiperSlide = el.attachment_urls
                .map((dt) => {
                    return `<div class="swiper-slide">
					<img src="${dt}"/>
				</div>`
                })
                .join('')

            const contentTemplate = `
			<h4 class="text-center">${el.city_name.replace('_', ' ')}</h4>
		<div class="pin-slider pin-content-slider-${el.city_name}">
			<div class="swiper-wrapper">
				${swiperSlide}
			</div>
		</div>
	`

            const marker = L.marker([el.long, el.lat], {
                icon: markerIcon,
            }).addTo(map)
            marker
                .bindPopup(contentTemplate, {
                    closeOnClick: false,
                })
                .on('popupopen', function () {
                    new Swiper(`.pin-content-slider-${el.city_name}`, {
                        slidesPerView: 1,
                    })
                })

            // Store marker with city_name for filtering
            markers.push({
                marker,
                city_name: el.city_name.toLowerCase().replace('_', ' '),
            })
        })

        // Function to fly to coordinates
        function flyToCoords(coords) {
            map.flyTo(coords, 15)
        }
        window.flyToCoords = flyToCoords

        const cardClass =
            '.map__container .cities-cards .cards .cards-list .card'
        const cards = document.querySelectorAll(cardClass)
        cards.forEach((item, index) => {
            const selector =
                cardClass + `.card-num-${index + 1}` + ' .arena-gallery'
            const instance = new Swiper(selector, {
                loop: false,
                spaceBetween: 0,
                breakpoints: {
                    200: {
                        slidesPerView: 1,
                        pagination: {
                            el: selector + ' .arena-pagination',
                            // centeredSlides: true,
                            clickable: true,
                        },
                    },
                    720: {
                        slidesPerView: 2,
                        pagination: {
                            el: selector + ' .arena-pagination',
                            // centeredSlides: false,
                            clickable: true,
                        },
                    },
                    1280: {
                        slidesPerView: 3,
                        pagination: {
                            el: selector + ' .arena-pagination',
                            clickable: true,
                        },
                    },
                    1600: {
                        slidesPerView: 3,
                        pagination: {
                            el: selector + ' .arena-pagination',
                            clickable: true,
                        },
                    },
                },
            })
        })

        // Live Search Functionality
        document
            .getElementById('search-input')
            .addEventListener('input', function () {
                const searchQuery = this.value.toLowerCase()

                // Filter cards
                cards.forEach((card) => {
                    const title = card.getAttribute('data-title').toLowerCase()
                    if (title.includes(searchQuery)) {
                        card.style.display = 'block'
                    } else {
                        card.style.display = 'none'
                    }
                })

                // Filter markers
                markers.forEach(({ marker, city_name }) => {
                    if (city_name.includes(searchQuery)) {
                        marker.addTo(map)
                    } else {
                        map.removeLayer(marker)
                    }
                })
            })
    }

    onInit() {
        this.update()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_map_component',
        WidgetMapHandler
    )
})
